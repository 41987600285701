// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/
/** Fonts **/
@font-face {
  font-family: "Roboto-Light";
  src: url("../assets/fonts/Roboto/Roboto-Light.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("../assets/fonts/Roboto/Roboto-Regular.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("../assets/fonts/Roboto/Roboto-Medium.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("../assets/fonts/Roboto/Roboto-Bold.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Black";
  src: url("../assets/fonts/Roboto/Roboto-Black.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

/** Ionic CSS Variables **/
:root {
  font-size: 10px;

  --ion-font-family-light: "Roboto-Light";
  --ion-font-family: "Roboto-Regular";
  --ion-font-family-medium: "Roboto-Medium";
  --ion-font-family-bold: "Roboto-Bold";
  --ion-font-family-lt-97-black: "Roboto-Black";

  --ion-color-primary: #C21B17;
  --ion-color-primary-rgb: 194, 59, 51;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #ab342d;
  --ion-color-primary-tint: #F6F6F6;
  --ion-color-primary-lighter: #fDf4f3;
  --ion-color-primary-2: #c21b17;

  --ion-color-secondary: #AA1B17;
  --ion-color-secondary-rgb: 142, 42, 42;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #7d2525;
  --ion-color-secondary-tint: #993f3f;
  --ion-color-secondary-light: #ee907b;
  --ion-color-secondary-white: #F6F6F6;
  --ion-color-secondary-white2: #DBDBDB;
  --ion-color-secondary-white3: rgba(154, 154, 154, 0.15);

  --ion-color-tertiary: #9f2842;
  --ion-color-tertiary-rgb: 159, 40, 66;
  --ion-color-tertiary-shade: #930800;
  --ion-color-tertiary-tint: #a93e55;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-gray: #4d4d4d;
  --ion-color-dark-gray: #343434;

  --ion-color-medium: #707070;
  --ion-color-medium-rgb: 118, 119, 122;
  --ion-color-medium-shade: #646464;
  --ion-color-medium-shade-rgb: 100, 100, 100;
  --ion-color-medium-tint: #A0A0A0;
  --ion-color-medium-tint-rgb: 154, 154, 154;
  --ion-color-medium-light: #b1b1b1;
  --ion-color-medium-light-rgb: 177, 177, 177;
  --ion-color-medium-line: #d8d8d8;
  --ion-color-medium-lighter: #f7f7f7;
  --ion-color-medium-lighter-2: #f6f7f8;
  --ion-color-medium-lightest: #e2e2e2;

  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #e0e0e0;
  --ion-color-light-tint: #f0f0f0;
  --ion-color-delta-positive: #6aa15a;
  --ion-color-delta-negative: #ba4e4f;

  --ion-color-product-primary: #C21B17;
  --ion-color-product-secondary: #C21B17;
  --ion-color-product-tertiary: #C21B17;

  --ion-color-fund-a: #ba4e4f;
  --ion-color-fund-b: #f3ae02;
  --ion-color-fund-c: #6aa15a;
  --ion-color-fund-d: #649eb2;
  --ion-color-fund-e: #487eef;

  --ion-color-alert-banner: #fdf3f3;
  --ion-color-alert-button: #fd6066;

  --ion-color-instagram: #ce4ead;
  --ion-color-facebook: #3b5998;
  --ion-color-linkedin: #2977c9;
  --ion-color-twitter: #00a2f9;
  --ion-color-youtube: #C21B17;
  --ion-color-whatsapp: #25d366;

  --ion-color-calendar-primary: #C21B17;
  --ion-color-calendar-secondary: #9f2842;
  --ion-color-orange-opaque: rgb(239, 123, 48); // #EF7B62
  --ion-color-gray-lightest: rgb(176, 178, 179); // #b0b2b3
  --ion-color-gray-lightest2: rgb(222, 222, 222); // #dedede
  --ion-color-red-light: rgb(224, 146, 141); // #E0928D
  --ion-color-background-light: rgb(252, 250, 250); // #FCFAFA
  --ion-color-white: rgb(255, 255, 255);
  --ion-color-text-disclaimer-bg: #607081;
  --ion-color-disclaimer-bg: #F6DDDC;
  --ion-color-disclaimer-box-shadow: 0px 0px 1px rgba(96, 112, 129, 0.05), 0px 1px 4px rgba(96, 112, 129, 0.1);
  --ion-color-shadow-lightest: rgba(0, 0, 0, 0.1);
  --ion-color-success: rgb(5, 139, 49);
  --ion-color-gray-dark: rgb(85, 85, 85);
  --ion-color-row-dark: rgb(241, 241, 241);
  --ion-color-success-lightest: rgb(227, 255, 241); // #E3FFF1
  --ion-color-sucess-dark: rgb(0, 114, 65); // #007241
  --ion-color-dark-grayest: #2C343C;
  --ion-color-bluish-dark: #607081;
  --ion-color-gray-bluish-light: #a6b1bD;

  --ion-color-status-alert-light: #fffee6;
  --ion-color-status-alert-medium: #ffcd00;
  --ion-color-status-alert-dark: #756201;
  --ion-color-status-error-light: #FFF1F1;
  --ion-color-status-error-medium: #FF3B3B;
  --ion-color-status-error-dark: #BC2A27;
  --ion-color-status-info-light: #e5f0ff;
  --ion-color-status-info-medium: #3672FB;
  --ion-color-status-info-dark: #285ad4;
  --ion-color-status-success-light: #E3FFF1;
  --ion-color-status-success-medium: #06C270;
  --ion-color-status-success-dark: #007241;
  --ion-color-status-warning-dark: #756201;
  --ion-color-status-warning-light: #fffee6;
  --ion-color-gray-darker: rgb(52, 52, 52);

  --shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  --container-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.1);
  --container-shadow-grayest: 0px 2px 4px var(#607081), 0px 6px 16px var(#607081);
  --elevation-04: 0 2px 4px rgba(96, 112, 129, 0.2), 0px 6px 16px rgba(96, 112, 129, 0.3);

  --icon-check: url(../assets/icon/i-check.svg);
  --i-check-outline: url(../assets/icon/i-check-outline.svg);
  --icon-valid: url(../assets/icon/i-valid.svg);
  --img-new-id: url(../assets/icon/new-id.png);
  --img-old-id: url(../assets/icon/old-id.png);
  --icon-savings-red: url(../assets/icon/i-savings-red.svg);
  --icon-lock-closed-outline: url(../assets/icon/i-lock-closed-outline.svg);
  --icon-trending-up-outline: url(../assets/icon/i-trending-up-outline.svg);
  --icon-plus: url(../assets/icon/i-plus.svg);
  --icon-pension-red: url(../assets/icon/i-pension-red.svg);
  --icon-write-outline-red: url(../assets/icon/i-write-outline-red.svg);
  --icon-arrow-down-gray: url(../assets/icon/i-arrow-down-gray.svg);
  --icon-accessibility: url(../assets/icon/i-accessibility.svg);
  --icon-a-plus: url(../assets/icon/i-a-plus.svg);
  --icon-a-minus: url(../assets/icon/i-a-minus.svg);
  --icon-high-contrast: url(../assets/icon/i-high-contrast.svg);
  --icon-document-text-outline: url(../assets/icon/i-document-text-outline.svg);
  --icon-cross-gray: url(../assets/icon/i-cross-gray.svg);
  --icon-cross-white: url(../assets/icon/i-cross-white.svg);
  --icon-cross-red: url(../assets/icon/i-cross-red.svg);
  --icon-study-red: url(../assets/icon/i-study-red.svg);
  --icon-person-locked: url(../assets/icon/i-person-locked.svg);

  --icon-status-alert: url(../assets/icon/i-status-alert.svg);
  --icon-status-error: url(../assets/icon/i-status-error.svg);
  --icon-status-info: url(../assets/icon/i-status-info.svg);
  --icon-status-success: url(../assets/icon/i-status-success.svg);
  --icon-status-alert-red: url(../assets/icon/i-status-alert-red.svg);

  --icon-green-dot: url(../assets/icon/i-green-dot.svg);
  --icon-gray-dot: url(../assets/icon/i-gray-dot.svg);

  --icon-question-red: url(../assets/icon/disclaimer-info.svg);
  --icon-alert-triangle: url(../assets/icon/alert.svg);
  --icon-informative-red: url(../assets/icon/i-informative-red.svg);
  --icon-informative-blue: url(../assets/icon/i-informative-blue.svg);
  --icon-time-outline: url(../assets/icon/i-time-outline.svg);

  --icon-cash-red: url(../assets/icon/cash-red.svg);
  --icon-mail-red: url(../assets/icon/mail-red.svg);
  --icon-percentage-red: url(../assets/icon/percentage-red.svg);
  --icon-profile-red: url(../assets/icon/profile-red.svg);
  --icon-confirm: url(../assets/icon/confirm.svg);
  --icon-saved: url(../assets/icon/saved.svg);
  --icon-save: url(../assets/icon/i-save-form.svg);
  --icon-next: url(../assets/icon/i-next-form.svg);
  --icon-next-red: url(../assets/icon/i-next-red-form.svg);
  --icon-back: url(../assets/icon/i-back-form.svg);
  --icon-alert-success: url(../assets/icon/alert-success.svg);
  --icon-page-prev: url(../assets/icon/i-page-prev.svg);
  --icon-page-next: url(../assets/icon/i-page-next.svg);
  --icon-sort-asc: url(../assets/icon/up-arrow-black.svg);
  --icon-sort-desc: url(../assets/icon/down-arrow-black.svg);
  --icon-download-gray: url(../assets/icon/i-dowload-gray.svg);
  --icon-document-2: url(../assets/icon/i-document-2.svg);
  --icon-circle: url(../assets/icon/i-circle.svg);
  --icon-circle-check: url(../assets/icon/i-circle-check.svg);
  --icon-circle-close: url(../assets/icon/i-circle-close.svg);
  --icon-download-2: url(../assets/icon/i-download-2.svg);
  --icon-close: url(../assets/icon/i-close.svg);
  --icon-down-arrow-red: url(../assets/icon/down-arrow-red.svg);
  --icon-up-arrow-red: url(../assets/icon/up-arrow-red.svg);
  --icon-flag: url(../assets/icon/flag.svg);
  --icon-check-current: url(../assets/icon/i-check-current.svg);
    --icon-check-pending: url(../assets/icon/i-check-pending.svg);

  --icon-back-gray: url(../assets/icon/i-back-gray.svg);
  --icon-back-dark: url(../assets/icon/i-back-dark.svg);
  --icon-back-black: url(../assets/icon/i-back-black.svg);

  --offset-x: 10px;

  --icon-laptop-videocall: url(../assets/icon/icon-laptop-videocall.png);

  --icon-left-chevron-white: url(../assets/icon/left-chevron-white.png);
  --vitalia-welcome-message: url(../assets/icon/help-message-red.png);
  --i-close-white: url(../assets/icon/i-close-white.svg);


  .high-contrast {
    --ion-color-medium: var(--ion-color-gray);
    --ion-color-medium-light: var(--ion-color-gray);
    --ion-color-medium-tint: var(--ion-color-gray);
  }

  /* Omni*/
  --ion-color-status-omni-accept: rgb(0, 92, 54); // #005C36
  --ion-color-status-omni-disable: rgb(167, 178, 190); // #A6B1BD
  --ion-color-status-omni-enter: var(--ion-color-dark-grayest); // #2C343C
  --ion-color-status-omni-expired: rgb(224, 142, 138); // #E08E8A
  --ion-color-status-omni-in-course: rgb(0, 64, 153); // #004099
  --ion-color-status-omni-in-progress: var(--ion-color-status-omni-accept); // #005C36
  --ion-color-status-omni-partial: var(--ion-color-bluish-dark); // #607081
  --ion-color-status-omni-pending-document: rgb(128, 107, 0); // #806B00
  --ion-color-status-omni-reject: rgb(153, 0, 0); // #990000
  --ion-color-starred: #F6F7F8;

  --container-shadow-grayest: 0px 2px 4px var(#607081),
  0px 6px 16px var(#607081);
  --card-container-box-shadow: 0px 2px 5px var(--card-box-shadow-primary),
  0px 25px 80px var(--card-box-shadow--secondary);
  --card-box-shadow-primary: rgba(96, 112, 129, 0.04);
  --card-box-shadow--secondary: rgba(96, 112, 129, 0.08);
  --avatar-box-shadow: 0px 0px 1px rgba(96, 112, 129, 0.2),
  0px 1px 4px rgba(96, 112, 129, 0.3);
}