/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Breakpoints */

@import "src/theme/breakpoints.scss";

/* Mixins */

@import "src/theme/mixins.scss";

@import "~swiper/swiper-bundle.css";

body {
  &.high-contrast {
    filter: grayscale(1);
  }
}

h3 {
  font-size: 22px;
}

ion-item {
  font-size: 1.6rem;
}

ion-chip {
  font-size: 1.4rem;
}

ion-button {
  font-size: 1.4rem;
  height: 36px;
}

ion-text {
  font-size: 1.4rem;
}

[hidden] {
  display: none !important;
}

// ******************* Icons ******************* /*
.i-no-repeat {
  background-repeat: no-repeat;
}

.i-pv-white-logo {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/plan-vital-white.svg');
}

.i-accept {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/accept.svg');
}

.i-account {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/account.svg');
}

.i-analysis {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/analysis.svg');
}

.i-alert {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/alert.svg');
}

.i-arrow-right {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/arrow-right.svg');
}

.i-arrow-up {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/arrow-up.svg');
}

.i-at {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/at.svg');
}

.i-admission-form {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/admission-form.svg');
}

.i-back-form {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/i-back-form.svg');
}

.i-bar {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/bar.svg');
  background-color: var(--ion-color-primary) !important;
}

.i-bell {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/bell.svg');
}

.i-letter {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/i-letter.svg');
}

.i-calendar {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/calendar.svg');
}

.i-calendar-bell {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/calendar-bell.svg');
}

.i-calendar-check {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/calendar-check.svg');
}

.i-calendar-clock {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/calendar-clock.svg');
}

.i-calendar-clock-red {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/calendar-clock-red.svg');
}

.i-calendar-star {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/calendar-star.svg');
}

.i-cellphone-outline {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/cellphone-outline.svg');
}

.i-chart {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/chart.svg');
}

.i-chart-down {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/chart-down.svg');
}

.i-chart-up {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/chart-up.svg');
}

.i-check {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/check.svg');
}

.i-check-outline {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/i-check-outline.svg');
}

.i-check-list {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/i-check-list.svg');
}

.i-checkmark {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/i-checkmark.svg');
}

.i-client {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/client.svg');
}

.i-customer-service {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/vitalia_customer.png');
}

.i-clock {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/clock.svg');
}

.i-cook {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/cook.svg');
}

.i-contact-outline {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/contact-outline.svg');
}

.i-disclaimer-info {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/disclaimer-info.svg');
}

.i-document {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/document.svg');
}

.i-dollar {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/i-dollar.svg');
}

.i-error {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/error.svg');
}

.i-email-outline {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/email-outline.svg');
}

.i-facebook {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/facebook.svg');
}

.i-facebook-outline {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/facebook-outline.svg');
}

.i-glove {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/glove.svg');
}

.i-glove-white {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/glove-white.svg');
}

.i-group {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/group.svg');
}

.i-group-white {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/group-white.svg');
}

.i-instagram {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/instagram.svg');
}

.i-instagram-outline {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/instagram-outline.svg');
}

.i-handshake {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/handshake.svg');
}

.i-linkedin {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/linkedin.svg');
}

.i-linkedin-outline {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/linkedin-outline.svg');
}

.i-location {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/location.svg');
}

.i-location-outline {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/location-outline.svg');
}

.i-lock {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/lock.svg');
}

.i-lock-primary {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/lock-primary.svg');
  background-color: var(--ion-color-primary) !important;
}

.i-message {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/message.svg');
}

.i-more {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/more.svg');
}

.i-more-red {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/more-red.svg');
}

.i-phone {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/phone.svg');
}

.i-phone-grey {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/phone-grey.svg');
}

.i-phone-red {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/phone-red.svg');
}

.i-screen-share {
  @extend .icon;
  background-image: url('~/assets/icon/i-screen-share.svg');
}

.i-pensioners {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/pensioners.svg');
}

.i-group-red {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/group-red.svg');
}

.i-question {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/question.svg');
}

.i-save {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/save.svg');
}

.i-search {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/search.svg');
}

.i-send-outline {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/send-outline.svg');
}

.i-support {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/support.svg');
}

.i-time-money {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/time-money.svg');
}

.i-twitter {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/twitter.svg');
}

.i-twitter-outline {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/twitter-outline.svg');
}

.i-worldwide {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/worldwide.svg');
  background-color: var(--ion-color-primary) !important;
}

.i-youtube {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/youtube.svg');
}

.i-youtube-outline {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/youtube-outline.svg');
}

.i-download {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/download.svg');
}

.i-authority {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/authority.svg');
}

.i-child {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/child-care.svg');
}

.i-pact {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/pact.svg');
}

.i-phone-outline {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/phone-outline.svg');
}

.i-revocation {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/revocation.svg');
}

.i-whatsapp {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/whatsapp.svg');
}

.i-whatsapp-outline {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/whatsapp-outline.svg');
}

.i-general-certificate {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/i-general-certificate.svg');
}

.i-transactional-certificate {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/i-transactional-certificate.svg');
}

.i-certificates-red {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/i-certificates-red.svg');
}

.i-certificates-white {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/i-certificates-white.svg');
}

.i-qr-code-app-mobile {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/qr-code-app-mobile.svg');
}

.i-google-play-es {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/google-play-icon.svg');
}

.i-google-play-white-es {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/google-play-icon-white.svg');
}

.i-google-play-black-es {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/google-play-icon-black.svg');
}

.i-google-play-ht {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/google-play-icon-ht.svg');
}

.i-google-play-white-ht {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/google-play-icon-white-ht.svg');
}

.i-app-store-es {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/app-store-icon.svg');
}

.i-app-store-white-es {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/app-store-icon-white.svg');
}

.i-app-store-black-es {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/app-store-icon-black.svg');
}

.i-app-store-ht {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/app-store-icon-ht.svg');
}

.i-app-store-white-ht {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/app-store-icon-white-ht.svg');
}

.i-app-gallery-es {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/app-gallery-icon.svg');
}

.i-app-gallery-white-es {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/app-gallery-icon-white.svg');
}

.i-app-gallery-black-es {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/app-gallery-icon-black.svg');
}

.i-app-gallery-ht {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/app-gallery-icon-ht.svg');
}

.i-app-gallery-white-ht {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/app-gallery-icon-white-ht.svg');
}

.i-cross-gray {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/i-cross-gray.svg');
}

.i-accessibility {
  @extend .i-no-repeat;
  background-image: var(--icon-accessibility);
}

.i-high-contrast {
  @extend .i-no-repeat;
  background-image: var(--icon-high-contrast);
}

.i-a-plus {
  @extend .i-no-repeat;
  background-image: var(--icon-a-plus);
}

.i-a-minus {
  @extend .i-no-repeat;
  background-image: var(--icon-a-minus);
}

.i-info-red {
  @extend .i-no-repeat;
  background-image: var(--icon-informative-red);
}

.i-info-blue {
  @extend .i-no-repeat;
  background-image: var(--icon-informative-blue);
}

.i-saved {
  @extend .i-no-repeat;
  background-image: var(--icon-saved);
}

.i-download-gray {
  @extend .icon;
  background-image: var(--icon-download-gray);
}

.i-document-2 {
  @extend .icon;
  mask-image: var(--icon-document-2);
}

.i-circle {
  @extend .icon;
  mask-image: var(--icon-circle);
}

.i-circle-check {
  @extend .icon;
  mask-image: var(--icon-circle-check);
}

.i-circle-close {
  @extend .icon;
  mask-image: var(--icon-circle-close);
}

.i-download-2 {
  @extend .icon;
  background-image: var(--icon-download-2);
}

.i-close {
  @extend .icon;
  background-image: var(--icon-close);
}

.i-back-gray {
  @extend .icon;
  background-image: var(--icon-back-gray);
}

.i-back-dark {
  @extend .icon;
  background-image: var(--icon-back-dark);
}

.i-back-black {
  @extend .icon;
  background-image: var(--icon-back-black);
}

.i-down-arrow-red {
  @extend .i-no-repeat;
  background-image: var(--icon-down-arrow-red);
}

.i-up-arrow-red {
  @extend .i-no-repeat;
  background-image: var(--icon-up-arrow-red);
}

.i-flag {
  @extend .i-no-repeat;
  background-image: var(--icon-flag);
}

.i-sort-asc {
  @extend .i-no-repeat;
  background-image: var(--icon-sort-asc);
}

.i-sort-desc {
  @extend .i-no-repeat;
  background-image: var(--icon-sort-desc);
}

.i-vitalia-cellphone {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/vitalia-cellphone.svg');
}

.i-vitalia-ok {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/vitalia_ok.svg');
}

.i-tooltip-blue {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/i-tooltip-blue.svg');
  width: 18px;
  height: 18px;
  margin-left: 4px;
}

.i-warning {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/i-warning.svg')
}

.icon.info {
  @extend .i-no-repeat;
  background-image: var(--icon-status-info);
}

.icon.error {
  @extend .i-no-repeat;
  background-image: var(--icon-status-error);
}

.icon.alert {
  @extend .i-no-repeat;
  background-image: var(--icon-status-alert);
}

.icon.success {
  @extend .i-no-repeat;
  background-image: var(--icon-status-success);
}

.disabled-component {
  cursor: not-allowed;
  opacity: 0.4;
  pointer-events: none;
}

.i-webchat {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/i-webchat.svg')
}

// ******************* Images ******************* /*
.img-superintendence-logo {
  @extend .i-no-repeat;
  background-image: url('~/assets/images/superintendence_logo.png');
}

.img-transfer-phone {
  @extend .i-no-repeat;
  background-image: url('~/assets/images/AFP_Planvital_home_afiliado_traspaso_claves.jpeg');
}

.img-promote-app-mobile {
  @extend .i-no-repeat;
  background-image: url('~/assets/images/AFP_Planvital_home_afiliado_traspaso_promote.jpeg');
}

.img-user-portrait {
  @extend .i-no-repeat;
  background-image: url('~/assets/images/user-portrait.png');
}

.img-savings-phone {
  @extend .i-no-repeat;
  background-image: url('~/assets/images/AFP_Planvital_home_afiliado_saving.png');
}

/* ******************* New Footer Channels Icons **************** */
.i-new-app {
  @extend .i-no-repeat;
  background-image: url('assets/icon/icon-app.svg');
}

.i-new-contact {
  @extend .i-no-repeat;
  background-image: url('assets/icon/icon-contact.svg');
}

.i-new-contact-international {
  @extend .i-no-repeat;
  background-image: url('assets/icon/icon-contact-international.svg');
}

.i-new-facebook {
  @extend .i-no-repeat;
  background-image: url('assets/icon/icon-facabook.svg');
}

.i-new-contact-form {
  @extend .i-no-repeat;
  background-image: url('assets/icon/icon-formulario-contacto.svg');
}

.i-new-infovital {
  @extend .i-no-repeat;
  background-image: url('assets/icon/icon-infovital.svg');
}

.i-new-instagram {
  @extend .i-no-repeat;
  background-image: url('assets/icon/icon-instagram.svg');
}

.i-new-linkedin {
  @extend .i-no-repeat;
  background-image: url('assets/icon/icon-linkedin.svg');
}

.i-new-sucursales {
  @extend .i-no-repeat;
  background-image: url('assets/icon/icon-sucursales.svg');
}

.i-new-whatsapp {
  @extend .i-no-repeat;
  background-image: url('assets/icon/icon-whatsapp.svg');
}

.i-new-xtwitter {
  @extend .i-no-repeat;
  background-image: url('assets/icon/icon-xtwitter.svg');
}

.i-new-youtube {
  @extend .i-no-repeat;
  background-image: url('assets/icon/icon-youtube.svg');
}

.i-new-vitalia {
  @extend .i-no-repeat;
  background-image: url('assets/icon/new-vitalia.svg');
}

.i-tooltip-red {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/i-tooltip-red.svg');
  width: 18px;
  height: 18px;
}

.i-check-square-red {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/i-check-square-red.svg');
  width: 18px;
  height: 18px;
}

.i-arrow-circle-up-red {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/i-arrow-circle-up-red.svg');
  width: 40px;
  height: 40px;
}

.i-arrow-circle-down-red {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/i-arrow-circle-down-red.svg');
  width: 40px;
  height: 40px;
}

.i-check-confirm-step {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/check-confirm-step.svg');
  background-size: contain;
  width: 100px;
  height: 100px;
}

.i-stamp-plan-vital {
  @extend .i-no-repeat;
  background-image: url('~/assets/icon/stamp-plan-vital.svg');
  background-size: contain;
  width: 100px;
  height: 100px;
}

/* ******************* Contact Modal New Icons ********************* */


/* ******************* Reset ******************* */

p {
  margin: 0;
  text-align: left;
}


/* ******************* Texts ******************* */

.title {
  @include font-sizes(2.7rem);
  color: var(--ion-color-primary);

  &.secondary {
    @include font-sizes(2.1rem);
  }

  &.tertiary {
    @include font-sizes(1.7rem);
  }

  &.light {
    color: var(--ion-color-light);
  }

  @media screen and (max-width: $mobile-xlarge) {
    @include font-sizes(2.4rem);

    &.secondary {
      @include font-sizes(2rem);
    }
  }

  @media screen and (max-width: $mobile-medium) {
    @include font-sizes(2rem);
  }
}

.subtitle {
  @include font-sizes(1.7rem);
  color: var(--ion-color-medium);

  @media screen and (max-width: $mobile-xlarge) {
    @include font-sizes(1.5rem);
  }

  @media screen and (max-width: $mobile-medium) {
    @include font-sizes(1.4rem);
  }
}

.disclaimer {
  color: var(--ion-color-medium);
}


/* ******************* Buttons ******************* */

.buttons-container {
  margin-top: 35px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .btn:not(:last-child) {
    margin-right: 30px;
  }
}

.button {
  @include font-sizes(1.4rem);
  --box-shadow: 0;
  --border-radius: 19px;
  text-transform: none;
  font-family: var(--ion-font-family-bold);

  &.primary {
    --background: var(--ion-color-primary);
  }

  &.secondary {
    --border-width: 1px;
    --border-style: solid;
    --color: var(--ion-color-primary);
    --background: var(--ion-color-light);
    --border-color: var(--ion-color-primary);
  }

  &.transparent {
    --padding-end: 0;
    --box-shadow: none;
    --padding-start: 0;
    --border-radius: 0;
    --background: transparent;
    --border-color: transparent;
    --ripple-color: transparent;
    --background-hover: transparent;
    --background-focused: transparent;
    --background-activated: transparent;
  }

  &.main {
    height: 45px;
    --padding-start: 20px;
    --padding-end: 20px;
    --border-radius: 23px;

    &::part(native) {
      width: max-content;
    }
  }

  &:hover {
    --box-shadow: var(--shadow);
  }

  &.close-modal {
    top: 30px;
    width: 30px;
    right: 30px;
    height: 30px;
    display: flex;
    font-size: 2.5rem;
    position: absolute;
    --border-radius: 50%;

    @media screen and (max-width: $desktop-small) {
      top: 25px;
      right: 25px;
      font-size: 2rem;
    }

    @media screen and (max-width: $mobile-xlarge) {
      top: 15px;
      right: 15px;
      font-size: 1.5rem;
    }

    .icon {
      color: var(--ion-color-medium);
    }
  }
}


/* ******************* General ******************* */

swiper-container {
  --swiper-pagination-color: var(--ion-color-primary);
  --swiper-pagination-bullet-inactive-color: var(--ion-color-light);
  --swiper-pagination-bullet-border-radius: 0;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-inactive-opacity: 1;
}

.modal {
  width: 100%;
  height: 100%;
  padding: 60px;
  overflow-y: auto;

  .title {
    padding-bottom: 20px;
    @include font-sizes(2.7rem);
    color: var(--ion-color-primary);
  }

  .description {
    text-align: justify;
    @include font-sizes(1.7rem);
  }

  @media screen and (max-width: $desktop-small) {
    padding: 45px;

    .title {
      @include font-sizes(2.5rem);
    }
  }

  @media screen and (max-width: $mobile-xlarge) {
    padding: 30px;

    .title {
      @include font-sizes(2.2rem);
    }

    .description {
      @include font-sizes(1.6rem);
    }
  }

  @media screen and (max-width: $mobile-medium) {
    padding: 60px 15px 15px;

    .title {
      @include font-sizes(1.9rem);
    }

    .description {
      @include font-sizes(1.5rem);
    }
  }

  @media screen and (max-width: 375px) {
    padding: 9px 0;
  }
}

.modal-default {
  --border-radius: 20px;
  --width: 60%;
  --height: auto;
  --max-height: 80%;

  &::part(content) {
    top: auto;
    left: auto;
    contain: content;
    position: relative;
    overflow: auto;
    height: auto;
  }

  @media screen and (max-width: $desktop-small) {
    --width: 80%;
  }

  @media screen and (max-width: $mobile-xlarge) {
    --width: 85%;
  }

  @media screen and (max-width: $mobile-medium) {
    --width: 100%;
    --height: 100%;
    --max-height: 100%;
    --border-radius: 0;
  }

  &.mini {
    &::part(content) {
      --width: 32%;
    }
  }

  &.full-modal {
    &::part(content) {
      width: auto;
    }
  }

  &.modal-login {
    &::part(content) {
      top: auto;
      left: auto;
      position: relative;
      height: 100%;
      overflow: hidden;
      contain: content;

      @media screen and (max-width: $desktop-medium) {
        overflow: auto;
        position: relative;
      }

      @media screen and (max-height: 900px) {
        overflow: auto;
        position: relative;
      }
    }

    --width: 80%;
    --max-height: 90%;

    @media screen and (min-width: $desktop-xlarge) {
      --max-width: 1650px;
    }

    @media screen and (min-height: 890px) {
      --max-height: 800px;
    }

    @media screen and (max-width: $mobile-xlarge) {
      --width: 85%;
    }

    @media screen and (max-width: $mobile-medium) {
      --width: 100%;
      --height: 100%;
      --max-height: auto;
      --border-radius: 0;
    }
  }
}

.modal-container {
  .modal-icon {
    width: 35px;
    height: 35px;
    margin: 20px auto;
    background-size: contain;
  }
}

.popover-header {
  --width: 250px;
}

.popover-valuesquote {
  --max-width: 450px;
  --width: 100vw;
}

.popover {
  --width: fit-content;
}

.menu {
  --height: 100%;
  --background: var(--ion-color-primary);
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.action-banner {
  display: flex;
  height: 115px;
  justify-content: center;
  align-items: center;
  color: var(--ion-color-medium-shade);
  background-color: var(--ion-color-secondary-white);

  @media screen and (max-width: 1000px) {
    height: auto;
    padding: 30px 0;
  }

  @media screen and (max-width: $mobile-xlarge) {
    padding: 30px;
  }

  @media screen and (max-width: $mobile-medium) {
    padding: 30px 15px;
  }
}

.centered-container {
  width: 100%;
  display: flex;
  justify-content: center;

  .button {
    --padding-start: 30px;
    --padding-end: 30px;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.faq-section {
  position: relative;
  width: 100%;
  padding: 30px 0 30px;
}

.general-container {
  max-width: #{$desktop-xlarge};
  margin: 0 auto;
}

.content-container {
  padding: 30px 60px;

  @media screen and (max-width: $mobile-xlarge) {
    padding: 30px;
  }

  @media screen and (max-width: $mobile-medium) {
    padding: 30px 15px;
  }
}

.hidden {
  visibility: hidden;
}

ion-item {
  --background: none;
  opacity: 1;
}

.skeleton-line {
  height: 15px;
  width: 500px;
  margin-bottom: 7px;
  border-radius: 4px;

  @media screen and (max-width: $mobile-large) {
    width: 280px;
  }

  &.title {
    margin-bottom: 25px;
  }

  &.small {
    width: 300px;

    @media screen and (max-width: $mobile-large) {
      width: 200px;
    }
  }

  &.xsmall {
    width: 150px;
  }

  &.large {
    width: 750px;

    @media screen and (max-width: $desktop-small) {
      width: 600px;
    }

    @media screen and (max-width: $mobile-large) {
      width: 280px;
    }
  }
}


/* ******************* Info card ******************* */

.info-card-container {
  display: flex;
  flex-wrap: wrap;
  padding-top: 50px;
  justify-content: space-between;
}

.info-card-container::after {
  content: "";
  width: 32%;
}

.info-card {
  width: 32%;
  margin-bottom: 30px;

  &.primary {
    min-height: 455px;
  }

  &.secondary {
    min-height: 425px;
  }

  &.tertiary {
    min-height: 365px;
  }

  @media screen and (max-width: $desktop-large) {
    width: 48%;
    min-height: auto;
  }

  @media screen and (max-width: $desktop-xsmall) {
    &.primary {
      width: 100%;
      min-height: 425px;

      &:last-child {
        margin: 0;
      }
    }
  }

  @media screen and (max-width: $mobile-large) {

    &.secondary,
    &.tertiary {
      min-height: 250px;
    }
  }

  @media screen and (max-width: 615px) {

    &.secondary,
    &.tertiary {
      width: 100%;

      &:last-child {
        margin: 0;
      }
    }
  }
}


/* ******************* Chartist ******************* */

.ct-series-a .ct-line {
  stroke-width: 1px;
  stroke: var(--ion-color-medium-shade);
}

.ct-chart-line {
  overflow: visible !important;
}

.ct-labels {
  font-family: var(--ion-font-family-bold);
}

.ct-label {
  font-size: 1.2rem;

  &.ct-horizontal {
    position: fixed;
    text-align: right;
    white-space: nowrap;
    transform-origin: 100% 0;
    justify-content: flex-end !important;
    transform: translate(-100%) rotate(-45deg);
  }
}


/* ******************* ngx-charts ***************** */

.gridline-path-vertical {
  display: none;
}

.gridline-path-horizontal {
  stroke-dasharray: 3;
}

.line-highlight {
  visibility: hidden;
}

.tooltip-bar {
  visibility: hidden;
}

ngx-tooltip-content.ngx-charts-tooltip-content.type-tooltip {
  .tooltip-caret {
    &.position-top {
      border-top-color: white;
    }

    &.position-right {
      border-right-color: white;
    }

    &.position-left {
      border-left-color: white;
    }

    box-shadow: var(--shadow);
  }

  font-family: var(--ion-font-family-bold);
  background-color: var(--ion-color-white);
  font-size: 12px;
  color: var(--ion-color-dark-01);
  box-shadow: var(--shadow);
}

.ngx-charts text {
  fill: rgba(0, 0, 0, .4);
  font-family: var(--ion-font-family-bold);
}


/* ******************* InnerHTML ******************* */

.dynamic-text {
  h1 {
    margin-bottom: 10px;
    color: var(--ion-color-primary);
  }

  h2 {
    margin-bottom: 10px;
    color: var(--ion-color-primary);
  }

  p {
    line-height: 30px;
    margin-bottom: 25px;
    color: var(--ion-color-medium);
  }

  table {
    width: 100%;
    margin-bottom: 25px;
    border: 1px solid rgba(var(--ion-color-medium-tint-rgb), 0.5);

    thead {
      background-color: rgba(var(--ion-color-medium-tint-rgb), 0.5);

      tr th {
        text-align: left;
        color: var(--ion-color-light);
      }
    }

    tbody tr {
      border-bottom: 1px solid rgba(var(--ion-color-medium-tint-rgb), 0.5);
    }
  }

  td,
  th {
    padding: 10px;
    color: var(--ion-color-medium);
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: 20px;
    text-indent: -10px;
    padding-left: 30px;
    list-style-position: outside;

    li {
      margin-bottom: 20px;
      color: var(--ion-color-medium);

      &::before {
        width: 10px;
        font-size: 1.5rem;
        content: "\2022";
        display: inline-block;
        color: var(--ion-color-primary);
        font-family: var(--ion-font-family-bold);
      }
    }
  }

  ol {
    margin: 0;
    padding: 0;
    line-height: 20px;
    padding-left: 30px;
    list-style-position: outside;

    li {
      margin-bottom: 20px;
      color: var(--ion-color-medium);
    }
  }

  @include font-sizes-dynamic-text(2.7rem,
    2rem,
    1.7rem);

  @media screen and (max-width: $desktop-xsmall) {
    @include font-sizes-dynamic-text(2.4rem,
      1.8rem,
      1.6rem);
  }

  @media screen and (max-width: $mobile-xlarge) {
    @include font-sizes-dynamic-text(2rem,
      1.7rem,
      1.5rem);
  }

  &.secondary {
    @include font-sizes-dynamic-text(2.4rem,
      1.7rem,
      1.6rem);

    @media screen and (max-width: $desktop-xsmall) {
      @include font-sizes-dynamic-text(2.2rem, 1.6rem, 1.5rem);
    }

    @media screen and (max-width: $mobile-xlarge) {
      @include font-sizes-dynamic-text(2rem,
        1.5rem,
        1.4rem);
    }
  }

  &.tertiary {
    @include font-sizes-dynamic-text(2.2rem,
      1.6rem,
      1.5rem);

    @media screen and (max-width: $desktop-xsmall) {
      @include font-sizes-dynamic-text(2rem, 1.5rem, 1.4rem);
    }

    @media screen and (max-width: $mobile-xlarge) {
      @include font-sizes-dynamic-text(1.8rem,
        1.4rem,
        1.3rem);
    }
  }

  &.quaternary {
    @include font-sizes-dynamic-text(1.8rem,
      1.2rem,
      1.1rem);

    p {
      line-height: 18px;
      margin-bottom: 12px;
    }
  }

  &.bullets {
    @include font-sizes-dynamic-text(2.7rem,
      1.7rem,
      1.7rem);

    p {
      line-height: 25px;
      padding-right: 20px;
      padding-left: 30px;
      margin-bottom: 10px;
    }

    ul,
    ol {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      li {
        width: 47%;
        margin-bottom: 35px;

        & br {
          display: block;
          content: "";
          margin-bottom: 5px;
        }
      }
    }

    @media screen and (max-width: $desktop-xsmall) {
      @include font-sizes-dynamic-text(2.4rem,
        1.6rem,
        1.6rem);

      ul li,
      ol li {
        width: 100%;
      }
    }

    @media screen and (max-width: $mobile-xlarge) {
      @include font-sizes-dynamic-text(2rem,
        1.5rem,
        1.5rem);
    }
  }

  &.card {
    ul {
      margin: 0;
      list-style: none;
      text-align: left;
      margin-left: 15px;
      text-indent: -10px;
      padding-left: 10px;

      li {
        margin-bottom: 0;
      }
    }
  }

  &.light {

    h1,
    p,
    ul li,
    ol li {
      color: var(--ion-color-light);
    }
  }
}


/* ******************* Forms Box ******************* */

.box {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 45px 60px;
  border-radius: 5px;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: var(--ion-color-light);
  box-shadow: var(--shadow);

  .title {
    color: var(--ion-color-primary);
    width: 100%;
    text-align: left;
    font-family: var(--ion-font-family-bold);
  }

  .primary {
    --padding-end: 45px;
    --padding-start: 45px;
  }

  .question-box {
    width: 100%;
    margin: 18px 0;
    --padding-start: 0;
    --ion-safe-area-right: 0;
    --background: transparent;
    color: var(--ion-color-dark);

    .label {
      @include font-sizes(1.6rem);
      padding-bottom: 15px;
      font-family: var(--ion-font-family-bold);
    }

    .icon-input {
      width: 100%;
      display: flex;

      .data-input {
        visibility: hidden;
        width: 0;
      }
    }
  }

  .centered-container {
    .button {
      width: 90%;
    }
  }

  .disclaimer {
    @include font-sizes(1rem);
    margin-bottom: 10px;
  }

  @media screen and (max-width: $desktop-small) {
    padding: 40px 55px;
  }

  @media screen and (max-width: $mobile-xlarge) {
    padding: 35px 45px;
  }

  @media screen and (max-width: $mobile-medium) {
    padding: 30px;
  }
}


/************    Articles     ************/

.principal-articles-container {
  padding: 50px 60px;
  color: var(--ion-color-medium-shade);

  .title-container .title {
    font-family: var(--ion-font-family-bold);
  }

  .items-container {
    width: 100%;
    display: flex;
    margin: 60px 0;

    &.guia-vital {
      margin-top: 30px;
      margin-bottom: 100px;
      @media screen and (max-width: $mobile-xlarge) {
        margin-bottom: 40px;
      }
    }

    .item-container {
      width: 50%;
      height: auto;
      display: flex;
      min-height: 360px;

      &.primary .card {
        width: 96%;
        height: 100%;
      }

      &.primary.no-footer {
        min-height: 270px;
      }

      &.secondary {
        flex-wrap: wrap;
        align-content: space-between;
        justify-content: space-between;

        .card {
          width: 48%;
          height: 48%;
        }
      }

      &.secondary.no-footer {
        min-height: 270px;
      }

      &.last-card {
        justify-content: end;
      }
    }
  }

  .featured-links {
    .item-container.primary {
      flex-direction: row-reverse;

      @media screen and (max-width: $mobile-xlarge) {
        padding-top: 25px;
      }
    }
  }

  @media screen and (max-width: $mobile-xlarge) {
    padding: 40px 30px;

    .title-container {
      .title {
        @include font-sizes(2rem);
      }

      .subtitle {
        @include font-sizes(1.4rem);
        width: 80%;
      }
    }

    .items-container {
      margin: 40px 0;
      flex-direction: column;

      .item-container {
        width: 100%;
        height: auto;
        min-height: auto;

        .card {
          margin-bottom: 25px;
        }

        &.primary .card {
          width: 100%;
        }

        &.secondary .card {
          height: auto;
          min-height: 165px;
        }

        &.primary.no-footer {
          min-height: 123.75px;

          .card {
            min-height: 123.75px;
          }
        }

        &.secondary.no-footer {
          min-height: 123.75px;

          .card {
            min-height: 123.75px;
          }
        }
      }
    }
  }

  @media screen and (max-width: $mobile-medium) {
    padding: 40px 15px;

    .items-container .item-container {
      &.primary .card {
        height: auto;
        min-height: 165px;
      }

      &.primary.no-footer .card {
        height: 123.75px;
        min-height: 123.75px;
      }

      &.secondary .card {
        width: 100%;
        height: 165px;
        margin-bottom: 25px;

      }

      &.secondary.no-footer .card {
        height: 123.75px;
        min-height: 123.75px;
      }
    }
  }

  @media screen and (max-width: $mobile-large) {
    .items-container .item-container {
      &.primary.no-footer {
        // height: 123.75px;
        min-height: 123.75px;
      }

      &.secondary.no-footer {
        height: auto;
        min-height: 123.75px;
      }
    }
  }
}


/* ******************* Stepper ******************* */

.stepper {
  &.complete {
    stroke: var(--ion-color-primary);
  }

  &.uncomplete {
    stroke: var(--color-gray-lightest);
  }
}


/* ******************* Forms ******************* */

.mat-mdc-form-field {
  @include font-sizes(1.7rem);
  line-height: 2.4rem;

  .mat-mdc-text-field-wrapper,
  .mdc-text-field--filled:not(.mdc-text-field--disabled),
  .mdc-text-field--filled.mdc-text-field--disabled {
    padding-right: 0;
    padding-left: 0;
    background-color: var(--ion-color-light);

    .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix {
      align-self: auto;
    }

    .mat-mdc-form-field-infix {
      border-top: 15px solid transparent;
      padding: 12px 6px 3px 0;

      .mat-mdc-floating-label {
        @include font-sizes(1.7rem);
        width: 100%;
      }

      .mdc-floating-label--float-above {
        transform: translateY(-170%) scale(0.75);
      }

      .mdc-text-field__input {
        @include font-sizes(1.9rem);
        color: var(--ion-color-medium);
        caret-color: var(--ion-color-medium);
      }
    }

    .mat-mdc-form-field-focus-overlay {
      background-color: var(--ion-color-light);
    }

    .mat-mdc-form-field-focus-overlay .mdc-line-ripple::after {
      background-color: var(--ion-color-dark);
    }
  }

  .mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
  .mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label,
  .mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label--float-above {
    color: var(--ion-color-gray-darker)
  }

  .mat-mdc-form-field-error {
    @include font-sizes(1.4rem);
    color: var(--ion-color-primary);
    font-family: var(--ion-font-family-bold);
  }

  .mat-mdc-form-field-error-wrapper {
    padding: 0px;
  }

  .i-validator {
    display: block;
    width: 20px;
    height: 20px;
    background: var(--icon-valid) center/contain no-repeat;
  }

  &.mat-form-field-invalid .i-validator {
    background-image: var(--icon-invalid);
  }

  &.ng-untouched .i-validator {
    visibility: hidden;
  }

  .mat-mdc-select {
    .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper {
      transform: none;
    }
  }
}

mat-form-field {

  .mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
    border-bottom-color: var(--ion-color-primary);
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
    border-bottom-color: var(--ion-color-dark);
  }

  .mdc-text-field--filled .mdc-line-ripple::after {
    border-bottom-color: var(--ion-color-dark);
  }

  .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
    border-bottom-color: var(--ion-color-primary);
  }

  .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
    border-bottom-color: var(--ion-color-primary);
  }

  .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
    border-bottom-color: var(--ion-color-primary);
  }
}

.mat-mdc-form-field {
  &.calendar-input {
    .mat-form-field-infix {
      display: flex;
      height: auto;
    }

    .mat-mdc-icon-button {
      top: 0;
      right: 0;
      position: absolute;
    }
  }
}


.mat-mdc-radio-group {
  .mat-mdc-radio-button.mat-mdc-radio-checked .mat-ripple-element {
    background-color: var(--ion-color-primary);
  }

  ::ng-deep .mat-mdc-radio-button.mat-mdc-radio-checked .mat-ripple-element {
    background-color: var(--ion-color-primary);
  }

  .mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before {
    background-color: var(--ion-color-primary);
  }

  .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
    border-color: var(--ion-color-primary);
  }

  .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
    border-color: var(--ion-color-primary);
  }

  .mat-mdc-radio-button .mdc-radio:not(:disabled):active .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
    border-color: var(--ion-color-primary);
  }

  .mat-mdc-radio-button .mdc-radio:not(:disabled):active .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
    border-color: var(--ion-color-primary);
  }

  .mat-mdc-radio-button .mdc-radio:not(:disabled):active .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
    border-color: var(--ion-color-primary);
  }

  .mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
    border-color: var(--ion-color-primary);
  }

  .mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
    border-color: var(--ion-color-primary);
  }

  .mat-mdc-radio-button {
    .mdc-radio {
      padding: 0px;
    }
  }
}

.ion-checkbox {
  color: var(--ion-color-medium-shade);
  @include font-sizes(1.6rem);
  line-height: 22px;
  font-family: var(--ion-font-family);
  white-space: initial;
}

.mat-mdc-checkbox {
  @include font-sizes(1.6rem);

  .mat-checkbox-label {
    white-space: normal;
  }

  .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected~.mdc-checkbox__ripple {
    background: none;
  }

  .mat-checkbox-inner-container {
    margin: 4px 8px auto 0;
  }

  .mat-checkbox-layout {
    .mat-checkbox-label {
      color: var(--ion-color-medium-shade);
      line-height: 22px;
      font-family: var(--ion-font-family);
      white-space: initial;
    }

    .mat-checkbox-inner-container {
      margin: 0;
      margin-right: 10px;

      .mat-checkbox-ripple .mat-ripple-element {
        background: var(--ion-color-primary);
      }
    }
  }

  &.mat-checkbox-checked {
    .mat-checkbox-layout .mat-checkbox-inner-container .mat-checkbox-background {
      background: var(--ion-color-primary);
    }
  }

  /* material design variables do not accept parameters such as var(color) */
  /* #C21B17 is equal to [--ion-color-primary] */
  --mdc-checkbox-state-layer-size: 16px;
  --mdc-checkbox-selected-focus-icon-color: #C21B17 !important;
  --mdc-checkbox-selected-hover-icon-color: #C21B17 !important;
  --mdc-checkbox-selected-icon-color: #C21B17 !important;
  --mdc-checkbox-selected-pressed-icon-color: #C21B17 !important;
  --mdc-checkbox-unselected-hover-icon-color: #C21B17 !important;
}

.fields-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 70px;
  justify-content: space-between;

  .mat-mdc-form-field {
    width: 48%;
    margin-top: 40px;

    &.extended {
      width: 100%;
    }

    @media (max-width: $mobile-large) {
      width: 100%;
    }
  }

  &.identification {
    flex-wrap: nowrap;

    @media (max-width: $mobile-large) {
      flex-wrap: wrap;
    }

    .mat-mdc-form-field {
      width: 44%;

      @media (max-width: $mobile-large) {
        width: 100%;

        &.info-btn {
          width: 85%;
        }
      }
    }
  }

  &.validated {
    flex-wrap: nowrap;
    align-items: flex-end;
    justify-content: flex-start;

    .btn {
      margin: 0 0 15px 30px;
    }
  }
}

.file-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  width: 100%;

  @media (max-width: 560px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .file-name {
    width: 75%;
    max-width: 450px;

    @media (max-width: 560px) {
      width: 100%
    }
  }

  .btn.small {
    padding: 11px 15px;
    margin-left: 38px;

    @media (min-width: 700px) {
      width: 155px;
    }

    @media (max-width: 560px) {
      align-self: flex-end;
    }
  }

  input[type="file"] {
    display: none;
  }
}

.mde-popover-direction-arrow {
  display: none;
}

.number-clean {

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

.modal-definition .mat-mdc-dialog-container {
  position: absolute;
  top: unset;
  bottom: 0;
  height: 40%;
  border-radius: 16px 16px 0px 0px;

  .mdc-dialog__surface {
    padding: 24px;
  }
}

.modal-definition {
  width: 100%;
  max-width: unset !important;
}

.mobile-view {
  flex-direction: row;

  @media screen and (max-width: 1085px) {
    flex-direction: column;
  }
}

.mobile-button-assist {
  margin-bottom: 16px;
}

.mobile-title {
  width: 100%;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.black-screen {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background: linear-gradient(rgba(44, 42, 41, .8) 0%, #2C2A29 100%);
  z-index: 99;
}

.cdk-overlay-pane {
  &.mat-mdc-tooltip-panel {
    .mat-mdc-tooltip {
      font-size: 1.4rem;
      width: auto;
      height: auto;
      padding: 10px;
      border-radius: 6px;
      line-height: 1.8rem;
      background-color: var(--ion-color-white);
      box-shadow: var(--shadow);
      font-family: var(--ion-font-family);

      .mdc-tooltip__surface {
        font-size: 1.4rem;
        color: var(--ion-color-gray);
        background-color: var(--ion-color-white);
      }
    }
  }
}

div .mat-mdc-select-panel {
  min-width: calc(100% + 32px);
  padding: 0 !important;

  mat-option {
    @include font-sizes(1.6rem);
  }
}

.cdk-overlay-container {

  .cdk-overlay-connected-position-bounding-box {
    .cdk-overlay-pane {
      min-width: 174px !important;
      width: unset !important;
    }
  }
}